import differenceInSeconds from 'date-fns/differenceInSeconds';
import DataProvider from './DataProvider';
import { createTiempo } from './tareas-functions';

export const empty = {};

export const estadosFichaje = {
    PENDIENTE: 'PENDIENTE',
    REVISADO: 'REVISADO',
    VERIFICADO: 'VERIFICADO',
    BLOQUEADO: 'BLOQUEADO',
};

class FichajesProvider extends DataProvider {
    constructor() {
        super('fichajes', empty);

        const today = new Date();

        this.fichajes = [
            {
                id: '1',
                fecha: new Date(2021, 8, 29),
                estado: 'PENDIENTE',
                totalHoras: createTiempo(560),
                marcajes: [
                    {
                        hora_entrada: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0),
                        hora_salida: null,
                    },
                ],
            },
            {
                id: '2',
                fecha: new Date(2021, 8, 28),
                estado: 'REVISADO',
                totalHoras: createTiempo(590),
                marcajes: [
                    {
                        hora_entrada: new Date(2021, 9, 29, 8, 0),
                        hora_salida: new Date(2021, 9, 29, 13, 0),
                    },
                    {
                        hora_entrada: new Date(2021, 9, 29, 15, 0),
                        hora_salida: new Date(2021, 9, 29, 19, 0),
                    },
                ],
            },
            {
                id: '3',
                fecha: new Date(2021, 8, 27),
                estado: 'REVISADO',
                totalHoras: createTiempo(560),
                marcajes: [
                    {
                        hora_entrada: new Date(2021, 9, 29, 8, 0),
                        hora_salida: new Date(2021, 9, 29, 13, 0),
                    },
                    {
                        hora_entrada: new Date(2021, 9, 29, 15, 0),
                        hora_salida: new Date(2021, 9, 29, 19, 0),
                    },
                ],
            },
            {
                id: '4',
                fecha: new Date(2021, 7, 28),
                estado: 'REVISADO',
                totalHoras: createTiempo(590),
                marcajes: [
                    {
                        hora_entrada: new Date(2021, 9, 29, 8, 0),
                        hora_salida: new Date(2021, 9, 29, 13, 0),
                    },
                    {
                        hora_entrada: new Date(2021, 9, 29, 15, 0),
                        hora_salida: new Date(2021, 9, 29, 19, 0),
                    },
                ],
            },
            {
                id: '5',
                fecha: new Date(2021, 7, 27),
                estado: 'VERIFICADO',
                totalHoras: createTiempo(560),
                marcajes: [
                    {
                        hora_entrada: new Date(2021, 9, 29, 8, 0),
                        hora_salida: new Date(2021, 9, 29, 13, 0),
                    },
                    {
                        hora_entrada: new Date(2021, 9, 29, 15, 0),
                        hora_salida: new Date(2021, 9, 29, 19, 0),
                    },
                ],
            },
        ];
    }

    getFichajesMonth = async (year, month) => {
        return this.getAll(`${year}/${month}`).then(updateHorasFichajes);
    };

    getMarcajesHoy = async () => {
        return this.action('marcajes-hoy-new');
    };

    _fichar = async (coords) => {
        return this.action('fichar', {
            method: 'post',
            body: JSON.stringify({ coords }),
        });
    };

    ficharEntrada = async (coords) => {
        return await this._fichar(coords);
    };

    ficharSalida = async (coords) => {
        return await this._fichar(coords);
    };

    verificarFichajes = async (ids) => {
        return this.action('revisar', {
            method: 'post',
            body: JSON.stringify({
                ids,
            }),
        }).then(updateHorasFichajes);
    };

    solicitarRevisionFichajes = async (ids) => {
        return this.action('solicitar-revisar', {
            method: 'post',
            body: JSON.stringify({
                ids,
            }),
        }).then(updateHorasFichajes);
    };
}

function getTiempoFichaje(fichaje) {
    let tiempoTotal = 0;

    fichaje.marcajes.forEach((marcaje) => {
        if (!marcaje.hora_entrada || !marcaje.hora_salida) return;

        const d = differenceInSeconds(new Date(marcaje.hora_salida), new Date(marcaje.hora_entrada));

        tiempoTotal += d;
    });

    return Math.round(tiempoTotal / 60);
}

function updateHorasFichaje(fichaje) {
    const minutosDia = 8 * 60;
    const tiempo = getTiempoFichaje(fichaje);
    const extras = tiempo > minutosDia ? tiempo - minutosDia : 0;

    return {
        ...fichaje,
        total_horas: createTiempo(tiempo),
        total_extras: createTiempo(extras),
    };
}

function updateHorasFichajes(fichajes) {
    return fichajes.map(updateHorasFichaje);
}

export function getTiempoTotalMarcajes(marcajes) {
    let tiempoTotal = 0;

    marcajes.forEach((marcaje) => {
        if (!marcaje.hora_entrada || !marcaje.hora_salida) return;

        const d = differenceInSeconds(new Date(marcaje.hora_salida), new Date(marcaje.hora_entrada));

        tiempoTotal += d;
    });

    return createTiempo(Math.round(tiempoTotal / 60));
}

// export const dataProvider = new DataProvider('fichajes', empty);
export const dataProvider = new FichajesProvider();
