import { Typography } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import isBefore from 'date-fns/isBefore';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { operariosProvider, tareasProvider } from '../../api';
import { processTareaForCalendar } from '../../api/tareas';
import { createTiempo } from '../../api/tareas-functions';
import useSplash from '../../contexts/SplashState';
import { format, formatISODate, isAllDay } from '../../utils';
import DrawerMenu from '../common/DrawerMenu';
import { PageBody } from '../common/PageBody';
import { PageHeader } from '../common/PageHeader';
import CalendarioIcon from '../icons/Calendario';
import { ButtonField } from '../tareas/common/ButtonField';
import { BaseSelectOperarioView } from '../tareas/editar/SelectOperarioView';
import MonthDaySelector, { useClassesByFechaCalendarioPlanificacion } from '../tareas/MonthDaySelector';
import TareaItemList from '../tareas/TareaItemList';
import SelectMonthYear from './SelectMonthYear';

const useStyles = makeStyles(
    (theme) => ({
        header: {
            paddingBottom: 0,
        },
        headerContent: {
            display: 'flex',
            flexDirection: 'column',
        },
        selectedDate: {
            color: 'white',
            marginTop: theme.spacing(2),
        },
        body: {
            padding: 16,
            paddingBottom: 84,
        },
        operariosFilterField: {
            height: 'auto',
            minHeight: 40,
        },
        addButton: {
            position: 'absolute',
            bottom: 16,
            right: 16,
            zIndex: 0,
        },
    }),
    { name: 'CalendarioPlanificacion' },
);

export default function CalendarioPlanificacion({ fecha: selectedDate }) {
    const classes = useStyles();
    const [operariosOptions, setOperariosOptions] = useState([]);
    const [selectedOperarios, setSelectedOperarios] = useState([]);
    useEffect(() => {
        operariosProvider.getAll('as_options').then(setOperariosOptions);
    }, []);

    const { showCustomComponent } = useSplash();
    const [resumen, setResumen] = useState([]);
    const [allTareas, setTareas] = useState([]);
    const history = useHistory();

    function changeFecha(value) {
        history.replace(`/calendario-planificacion/${value}`);
    }

    const fecha = new Date(selectedDate);

    const year = format(fecha, 'Y');
    const month = format(fecha, 'M');

    const fetchUrl = `calendario-planificacion-operario?year=${year}&month=${month}`;

    useEffect(() => {
        tareasProvider.getAll(fetchUrl).then((resultado) => {
            const tareaList = resultado.tareas.map(processTareaForCalendar);
            tareaList.sort((a, b) => (isBefore(a.fechaInicio, b.fechaInicio) ? -1 : 1));

            setTareas(tareaList);
            setResumen(resultado.resumen);
            // setSolicitudes(resultado.solicitudes);
        });
    }, [fetchUrl]);

    const tareas = useMemo(
        () =>
            allTareas.filter(
                (tarea) =>
                    tarea.fechas &&
                    tarea.fechas[selectedDate] &&
                    (selectedOperarios.length === 0 ||
                        selectedOperarios.some((op) =>
                            tarea.fechas[selectedDate].operarios.map((o) => o.id).includes(op.id),
                        )),
            ),
        [allTareas, selectedOperarios, selectedDate],
    );

    const selectedOperarioIds = useMemo(() => selectedOperarios.map((op) => op.id), [selectedOperarios]);
    const getClassesByFechaJornada = useClassesByFechaCalendarioPlanificacion(selectedOperarioIds);

    const { tareasValidas } = useMemo(() => {
        const tareasValidas = tareas
            .filter((tarea) => tarea.fechas && tarea.fechas[selectedDate] !== undefined)
            .map((tarea) => {
                const tareaFecha = tarea.fechas[selectedDate];

                return {
                    ...tarea,
                    vehiculo: tareaFecha.vehiculo,
                    marcajes: tareaFecha.marcajes,
                    operarios: tareaFecha.operarios,
                    fechaInicio: tareaFecha.fecha_inicio ? new Date(tareaFecha.fecha_inicio) : null,
                    fechaFin: tareaFecha.fecha_fin ? new Date(tareaFecha.fecha_fin) : null,
                    fechaVisita: tareaFecha.fecha_visita ? new Date(tareaFecha.fecha_visita) : null,
                    fechaInicioJornada: tareaFecha.fecha_inicio_jornada
                        ? new Date(tareaFecha.fecha_inicio_jornada)
                        : null,
                    confirmada: tareaFecha.confirmada,
                    salido: tareaFecha.salido,
                    tiempoHoy: tareaFecha.minutos !== null ? createTiempo(tareaFecha.minutos) : null,
                };
            });
        tareasValidas.sort((a, b) => {
            const isAllDayA = isAllDay(a.fechaInicio, a.fechaFin);
            const isAllDayB = isAllDay(b.fechaInicio, b.fechaFin);

            if (isAllDayA && !isAllDayB) {
                return 1;
            }
            if (!isAllDayA && isAllDayB) {
                return -1;
            }

            return isBefore(a.fechaInicio, b.fechaInicio) ? -1 : 1;
        });

        return {
            tareasValidas,
        };
    }, [selectedDate, tareas]);

    const classesByFecha = useMemo(() => getClassesByFechaJornada(resumen), [resumen, getClassesByFechaJornada]);

    function handleChangeFecha() {
        showCustomComponent(({ closeSplash }) => (
            <SelectMonthYear
                currentMonth={month}
                currentYear={year}
                onClose={closeSplash}
                onSelect={(month, year) => {
                    changeFecha(formatISODate(new Date(year, month - 1, 1)));
                    closeSplash();
                }}
            />
        ));
    }
    return (
        <>
            <PageHeader
                startButton={
                    <IconButton onClick={handleChangeFecha}>
                        <CalendarioIcon />
                    </IconButton>
                }
                title={format(fecha, 'MMMM yyyy')}
                onTitleClick={handleChangeFecha}
                endButton={<DrawerMenu />}
                className={classes.header}
            >
                <div className={classes.headerContent}>
                    <ButtonField
                        style={{ marginTop: 16 }}
                        value={selectedOperarios.map((op) => op.nombre).join(', ')}
                        placeholder='Todos los operarios'
                        onClick={() => {
                            showCustomComponent(({ closeSplash }) => (
                                <BaseSelectOperarioView
                                    currentValue={selectedOperarios}
                                    setFieldValue={(selectedOperarios) => {
                                        setSelectedOperarios(selectedOperarios);
                                        closeSplash();
                                    }}
                                    onClose={closeSplash}
                                    operarios={operariosOptions}
                                />
                            ));
                        }}
                        onClear={selectedOperarios.length > 0 ? () => setSelectedOperarios([]) : null}
                        classes={{
                            container: classes.operariosFilterField,
                        }}
                    />
                    <MonthDaySelector
                        fecha={fecha}
                        classesByFecha={classesByFecha}
                        onDateChange={(date) => history.push(`/calendario-planificacion/${formatISODate(date)}`)}
                    />
                    <Typography className={classes.selectedDate}>{format(fecha, 'EEEE, dd MMMM yyyy')}</Typography>
                </div>
            </PageHeader>
            <PageBody className={classes.body} bodyGap={8} color='dark' paddingTop={0}>
                <TareaItemList tareas={tareasValidas} solicitudes={[]} hideTiempo />

                <Fab
                    color='primary'
                    className={classes.addButton}
                    onClick={() =>
                        history.push('/tarea/nueva', { fecha: new Date(selectedDate), operarios: selectedOperarios })
                    }
                >
                    <AddIcon />
                </Fab>
            </PageBody>
        </>
    );
}

CalendarioPlanificacion.propTypes = {
    fecha: PropTypes.string,
};
