import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import isToday from 'date-fns/isToday';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import WheelPicker from 'react-simple-wheel-picker';
import { tareasProvider } from '../../api';
import { createTiempo } from '../../api/tareas-functions';
import useAuthState from '../../contexts/AuthState';
import useSplash from '../../contexts/SplashState';
import { format, formatISODate } from '../../utils';
import { PageHeader } from '../common/PageHeader';
import UpdateOperariosTime from './UpdateOperariosTime';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: 1,
        '& li': {
            justifyContent: 'center',
        },
        '& li span': {
            display: 'none',
        },
    },
}));

const dataHours = Array(16)
    .fill(0)
    .map((_, i) => ({
        id: i.toString(),
        value: i.toString().padStart(2, '0'),
    }));

const INTERVAL = 5;
const dataMinutes = [];

for (let i = 0; i < 60; i += INTERVAL) {
    dataMinutes.push({
        id: i.toString(),
        value: i.toString().padStart(2, '0'),
    });
}

export function BaseEditTime({ title, onClose, onAccept, tiempo: defaultTiempo, selectedDate }) {
    const styles = useStyles();
    const [tiempo, setTiempo] = useState(null);

    useEffect(() => {
        setTiempo(defaultTiempo || { horas: 0, minutos: 0 });
    }, [defaultTiempo]);

    const handleOnChange = (field) => (value) => {
        if (!tiempo) return;

        setTiempo((tiempo) => ({ ...tiempo, [field]: Number(value.id) }));
    };

    const timePickerProps = {
        height: 380,
        width: null,
        itemHeight: 76,
        color: 'rgb(255,255,255,0.4)',
        activeColor: '#fff',
        focusColor: 'transparent',
        backgroundColor: 'transparent',
        shadowColor: 'none',
        fontSize: 48,
    };

    console.log(defaultTiempo);

    return (
        <PageHeader
            title={title}
            fill
            startButton={
                <IconButton onClick={onClose}>
                    <CloseIcon style={{ color: 'white' }} />
                </IconButton>
            }
            endButton={
                <IconButton onClick={() => onAccept(tiempo)} disabled={tiempo === null}>
                    <DoneIcon style={{ color: 'white' }} />
                </IconButton>
            }
        >
            <Typography
                variant='h3'
                style={{
                    textAlign: 'center',
                    marginTop: 48,
                    marginBottom: 48,
                    position: 'relative',
                }}
            >
                {/* eslint-disable-next-line quotes */}
                Jornada {isToday(selectedDate) ? 'de HOY' : 'del ' + format(selectedDate, "dd 'de' MMMM yyyy")}
            </Typography>

            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div className={styles.root}>
                    {tiempo !== null && (
                        <>
                            <WheelPicker
                                data={dataHours}
                                onChange={handleOnChange('horas')}
                                selectedID={tiempo.horas.toString()}
                                {...timePickerProps}
                            />
                            <WheelPicker
                                data={dataMinutes}
                                onChange={handleOnChange('minutos')}
                                selectedID={tiempo.minutos.toString()}
                                {...timePickerProps}
                            />
                        </>
                    )}
                </div>
            </div>
        </PageHeader>
    );
}

BaseEditTime.propTypes = {
    selectedDate: PropTypes.any,
    onClose: PropTypes.func,
    onAccept: PropTypes.func,
    tiempo: PropTypes.any,
    title: PropTypes.string,
};

export default function EditTime({ id, fecha, jornadaId }) {
    const history = useHistory();
    const [tiempo, setTiempo] = useState(null);
    const { showCustomComponent } = useSplash();
    const {
        userInfo: {
            preferencias: { copiar_tiempo_operarios: copiarTiempoOperarios },
        },
    } = useAuthState();

    const location = useLocation();

    const selectedOperarios = location?.state?.operarios;

    const selectedDate = useMemo(() => fecha ?? formatISODate(new Date()), [fecha]);

    useEffect(() => {
        if (tiempo !== null) setTiempo(null);

        const endpoint = jornadaId ? `tiempo-jornada/${jornadaId}` : `tiempo/${selectedDate}`;

        if (selectedOperarios) {
            setTiempo(createTiempo(0));
            return;
        }

        tareasProvider
            .actionOnId(id, endpoint, null, { method: 'get' })
            .then((minutos) => setTiempo(createTiempo(minutos)));
    }, [fecha, jornadaId, selectedOperarios]);

    return (
        <BaseEditTime
            title={'Establecer tiempo'}
            onClose={() => {
                history.goBack();
            }}
            selectedDate={selectedDate}
            tiempo={tiempo}
            onAccept={(tiempo) => {
                const endpoint = jornadaId ? `tiempo-jornada/${jornadaId}` : `tiempo/${selectedDate}`;

                if (copiarTiempoOperarios) {
                    showCustomComponent(({ closeSplash }) => (
                        <UpdateOperariosTime
                            closeSplash={closeSplash}
                            id={id}
                            fecha={fecha}
                            tiempo={tiempo}
                            onSave={(operarios) => {
                                tareasProvider.actionOnId(id, endpoint, { tiempo, operarios }).then(() => {
                                    closeSplash();
                                    history.goBack();
                                });
                            }}
                        />
                    ));
                    return;
                }

                const params = { tiempo };

                if (!jornadaId && selectedOperarios) {
                    params.operarios = selectedOperarios.map((op) => op.id);
                    params.set_self = false;
                }

                tareasProvider.actionOnId(id, endpoint, params).then(() => history.goBack());
            }}
        />
    );
}

EditTime.propTypes = {
    fecha: PropTypes.any,
    id: PropTypes.any,
    jornadaId: PropTypes.any,
};
