import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fichajesProvider } from '../../../api';
import { getTiempoTotalMarcajes } from '../../../api/fichajes';
import { getMinutos } from '../../../api/tareas-functions';
import { format, formatFullDateTime, formatTiempo } from '../../../utils';
import Button from '../../common/Button';
import { ButtonDialog } from '../../common/ButtonDialog';
import EntradaIcon from '../../icons/Entrada';
import SalidaIcon from '../../icons/Salida';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(1),
            flex: 1,
        },
        loading: {
            color: 'white',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: `${theme.spacing(3)}px`,
            '& .MuiCircularProgress-root': {
                color: 'white',
            },
        },
        totalHoy: {
            color: 'white',
            fontSize: 20,
            padding: theme.spacing(1.5, 2),
            borderRadius: 24,
            lineHeight: '20px',
            marginBottom: theme.spacing(3),
            alignSelf: 'center',
            '&.success': {
                backgroundColor: theme.palette.success.main,
            },
            '&.warning': {
                backgroundColor: theme.palette.warning.main,
            },
            '&.error': {
                backgroundColor: theme.palette.error.main,
            },
        },
        lista: {
            marginBottom: theme.spacing(2),
            width: '100%',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
        },
        marcaje: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(2),
            borderTop: '1px solid rgba(255, 255, 255, 0.5)',
            '&:last-of-type': {
                borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
            },
            '&>div': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flex: 1,
            },
            '& .MuiTypography-body1': {
                fontSize: 14,
                fontWeight: 400,
            },
            '& .MuiTypography-body2': {
                fontSize: 32,
                fontWeight: 400,
            },
            '& .MuiSvgIcon-root': {
                fontSize: 32,
            },
        },
        dialogContentText: {
            fontSize: 16,
            color: '#213061',
            '& div': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            '& div + div': {
                marginTop: theme.spacing(1.5),
            },
        },
        buttons: {
            marginTop: 'auto',
            padding: theme.spacing(0, 2),
        },
    }),
    { name: 'ListaFichajesHoy' },
);

export default function ListaFichajesHoy({ style, className }) {
    const classes = useStyles();
    const [marcajes, setMarcajes] = useState(null);
    const [minutosHorario, setMinutosHorario] = useState(0);
    const [coordenadas, setCoordenadas] = useState(null);
    const history = useHistory();

    useEffect(() => {
        fichajesProvider.getMarcajesHoy().then((res) => {
            setMarcajes(res.marcajes);
            setMinutosHorario(res.horario);
        });
    }, []);

    useEffect(() => {
        if (navigator.geolocation) {
            const watchId = navigator.geolocation.watchPosition(
                function ({ coords: { latitude, longitude, accuracy } }) {
                    setCoordenadas({ latitude, longitude, accuracy });
                },
                function () {
                    setCoordenadas(null);
                },
                {
                    enableHighAccuracy: true,
                },
            );

            return () => navigator.geolocation.clearWatch(watchId);
        }
    }, []);

    const tiempoHoy = useMemo(() => (marcajes ? getTiempoTotalMarcajes(marcajes) : 0), [marcajes]);
    const minutosHoy = getMinutos(tiempoHoy);
    const tiempoHoyClass = minutosHoy === 0 ? 'error' : minutosHoy >= minutosHorario ? 'success' : 'warning';

    const puedeEntrar = marcajes && (marcajes.length === 0 || marcajes[marcajes.length - 1].hora_salida !== null);

    const ficharEntrada = () => fichajesProvider.ficharEntrada(coordenadas).then(setMarcajes);
    const ficharSalida = () => fichajesProvider.ficharSalida(coordenadas).then(setMarcajes);

    return (
        <div className={classnames(className, classes.root)} style={style}>
            {marcajes === null ? (
                <div className={classes.loading}>
                    <CircularProgress />
                    <Typography variant='h3'>Cargando fichajes</Typography>
                </div>
            ) : (
                <>
                    <div className={classnames(classes.totalHoy, tiempoHoyClass)}>{formatTiempo(tiempoHoy)}</div>

                    {marcajes.length > 0 && (
                        <div className={classes.lista}>
                            {marcajes.map((fichaje, i) => (
                                <div key={i} className={classes.marcaje}>
                                    <div>
                                        <Typography variant='body1'>Entrada</Typography>
                                        <Typography variant='body2'>{format(fichaje.hora_entrada, 'HH:mm')}</Typography>
                                    </div>

                                    <ArrowForwardIcon />

                                    <div>
                                        <Typography variant='body1'>Salida</Typography>
                                        <Typography variant='body2'>
                                            {fichaje.hora_salida ? format(fichaje.hora_salida, 'HH:mm') : '--:--'}
                                        </Typography>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    <div className={classes.buttons}>
                        {puedeEntrar ? (
                            <ButtonDialog
                                button={
                                    <Button color='primaryFilled' rounded style={{ marginBottom: 16 }} fullWidth>
                                        Fichar entrada
                                    </Button>
                                }
                                onAccept={ficharEntrada}
                                title='¿Estás seguro que quieres fichar la entrada?'
                                content={
                                    <div className={classnames(classes.lista, classes.dialogContentText)}>
                                        <div className='entrada'>
                                            <EntradaIcon /> Entrada
                                        </div>
                                        <div>{formatFullDateTime(new Date())}</div>
                                    </div>
                                }
                                okText='Fichar entrada'
                                okColor='success'
                            />
                        ) : (
                            <ButtonDialog
                                button={
                                    <Button color='primaryFilled' rounded style={{ marginBottom: 16 }} fullWidth>
                                        Fichar salida
                                    </Button>
                                }
                                onAccept={ficharSalida}
                                title='¿Estás seguro que quieres fichar la salida?'
                                content={
                                    <div className={classnames(classes.lista, classes.dialogContentText)}>
                                        <div className='salida'>
                                            <SalidaIcon /> Salida
                                        </div>
                                        <div>{formatFullDateTime(new Date())}</div>
                                    </div>
                                }
                                okText='Fichar salida'
                                okColor='error'
                            />
                        )}

                        <Button
                            style={{ marginBottom: 16 }}
                            color='primary'
                            rounded
                            outline
                            fullWidth
                            onClick={() => history.push('/fichajes/historico')}
                        >
                            Ver historial de fichajes
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
}

ListaFichajesHoy.propTypes = {
    className: PropTypes.any,
    style: PropTypes.any,
};
