import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DoneIcon from '@material-ui/icons/Done';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { PageBody } from '../../common/PageBody';
import { PageHeader } from '../../common/PageHeader';
import { useTareaState } from '../TareaView';
import FirmaManual from './FirmaManual';

const useStyles = makeStyles(
    (theme) => ({
        label: {
            color: '#818CAE',
            fontSize: 12,
            margin: theme.spacing(1, 0),
        },
        input: {
            backgroundColor: 'white',
            borderRadius: 4,
            padding: theme.spacing(0.5, 1),
            fontSize: 13,
            color: '#818CAE',
            marginBottom: theme.spacing(3),
        },
        clearSignature: {
            position: 'absolute',
            top: 8,
            right: 8,
            boxShadow: 'none',
            textTransform: 'none',
            color: 'white',
            backgroundColor: '#4D86FF',
            '&:focus, &:active': {
                backgroundColor: '#4D86FF',
            },
        },
        signatureWrapper: {
            flex: 1,
            border: '1px dashed #bababa',
            borderRadius: 4,
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            marginBottom: theme.spacing(3),
        },
    }),
    { name: 'FirmarView' },
);

export function FirmarView(props) {
    const { tarea } = useTareaState();

    return <BaseFirmarView cliente={tarea.cliente} descripcion={tarea.descripcion} {...props} />;
}

export function BaseFirmarView({ cliente, descripcion, onClose, onSave, defaultNombre = '' }) {
    const classes = useStyles();
    const [signatureResult, setSignatureResult] = useState({
        nombre: defaultNombre,
        imagen: null,
        coordenadas: null,
    });

    const snackbar = useSnackbar();

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    setSignatureResult((signature) => ({
                        ...signature,
                        coordenadas: `${position.coords.latitude},${position.coords.longitude}`,
                    }));
                },
                function (err) {
                    console.log(err);

                    if (err.code === 1) {
                        snackbar.showMessage(
                            'Permisos de localización desactivados. Por favor, activalos en la configuración de tu navegador web',
                        );
                    }

                    setSignatureResult((signature) => ({
                        ...signature,
                        coordenadas: null,
                    }));
                },
                {
                    maximumAge: 60000,
                    timeout: 5000,
                    enableHighAccuracy: true,
                },
            );
        }
    }, []);

    useEffect(() => {
        setSignatureResult((signature) => ({ ...signature, nombre: defaultNombre }));
    }, [defaultNombre]);

    const input = useRef(null);

    const setNombreOnChange = (ev) => {
        const value = ev.target.value;
        setSignatureResult((current) => ({ ...current, nombre: value }));
    };

    const isSignatureValid = signatureResult.imagen !== null && signatureResult.nombre !== '';

    return (
        <React.Fragment>
            <PageHeader
                title='Conformidad'
                startButton={
                    <IconButton onClick={onClose}>
                        <ArrowBackIcon style={{ color: 'white' }} />
                    </IconButton>
                }
                endButton={
                    <IconButton
                        onClick={() => onSave(signatureResult)}
                        disabled={!isSignatureValid}
                        style={{ opacity: isSignatureValid ? 1 : 0.4 }}
                    >
                        <DoneIcon style={{ color: 'white' }} />
                    </IconButton>
                }
            >
                <Typography variant='h2'>{cliente}</Typography>
                <Typography variant='h3'>{descripcion}</Typography>
            </PageHeader>
            <PageBody>
                <Typography className={classes.label}>Introduce tu nombre completo</Typography>
                <InputBase
                    inputRef={input}
                    className={classes.input}
                    onChange={setNombreOnChange}
                    value={signatureResult.nombre}
                />
                <FirmaManual
                    onBegin={() => input.current.blur()}
                    setSignatureResult={setSignatureResult}
                    signatureResult={signatureResult}
                />
                {/* <Typography className={classes.label}>Firma en el recuadro</Typography> */}
                {/* <div className={classes.signatureWrapper}> */}
                {/*    {isSignatureValid && */}
                {/*    <Button */}
                {/*        size='small' */}
                {/*        onClick={clearInput} */}
                {/*        className={classes.clearSignature} */}
                {/*        startIcon={<SyncIcon />} */}
                {/*        variant='contained' */}
                {/*    > */}
                {/*        Repetir */}
                {/*    </Button>} */}
                {/*    <SignatureCanvas */}
                {/*        ref={measuredRef} */}
                {/*        penColor='black' */}
                {/*        clearOnResize={false} */}
                {/*        onBegin={() => input.current.blur()} */}
                {/*        onEnd={setSignatureOnChange} */}
                {/*    /> */}
                {/* </div> */}
            </PageBody>
        </React.Fragment>
    );
}

BaseFirmarView.propTypes = {
    cliente: PropTypes.string,
    descripcion: PropTypes.string,
    defaultNombre: PropTypes.string,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
};
