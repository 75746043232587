import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { fichajesProvider } from '../../../api';
import { createTiempo } from '../../../api/tareas-functions';
import { formatFullDate, formatTiempo, formatTime } from '../../../utils';
import Button from '../../common/Button';
import SlidingDialog from '../../common/SlidingDialog';

const useStyles = makeStyles(
    (theme) => ({
        item: {
            display: 'flex',
            flexDirection: 'row',
            borderTop: '1px solid #23395F',
            padding: theme.spacing(2, 3),
            gap: `${theme.spacing(2)}px`,
            '&:first-of-type': {
                marginTop: theme.spacing(2),
            },
        },
        row: {
            display: 'flex',
            alignItems: 'center',
            flex: 1,
        },
        label: {
            fontSize: 14,
            fontWeight: 500,
            marginRight: theme.spacing(1),
        },
        fullWidth: {
            flex: 1,
        },
        time: {
            fontSize: 18,
            fontWeight: 500,
        },
        subItems: {
            gap: `${theme.spacing(4)}px`,
        },
        subItem: {
            display: 'flex',
            flexDirection: 'column',
        },
        label2: {
            fontSize: 12,
            fontWeight: 400,
        },
        time2: {
            fontSize: 16,
            fontWeight: 500,
        },
        buttons: {
            display: 'flex',
            flexDirection: 'column',
            borderTop: '1px solid #23395F',
            padding: theme.spacing(2, 3),
            gap: `${theme.spacing(1)}px`,
        },
    }),
    { name: 'FichajeDialog' },
);

export default function FichajeDialog({ open, onClose, fichaje, onUpdated }) {
    const classes = useStyles();

    const title = fichaje ? formatFullDate(fichaje.fecha) : '';
    return (
        <>
            <SlidingDialog open={open} onClose={onClose} title={title} removePadding>
                {fichaje?.marcajes.map((marcaje, i) => (
                    <div className={classes.item} key={i}>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Entrada:</Typography>
                            <Typography className={classes.time}>{formatTime(marcaje.hora_entrada)}</Typography>
                        </div>
                        <div className={classes.row}>
                            <Typography className={classes.label}>Salida:</Typography>
                            <Typography className={classes.time}>
                                {marcaje.hora_salida ? formatTime(marcaje.hora_salida) : '--:--'}
                            </Typography>
                        </div>
                    </div>
                ))}
                {fichaje && (
                    <div className={classes.item}>
                        <div className={classes.row}>
                            <Typography className={clsx(classes.label, classes.fullWidth)}>Horas fichadas:</Typography>
                            <Typography className={classes.time}>
                                {formatTiempo(createTiempo(fichaje.totales.minutos_trabajados))}
                            </Typography>
                        </div>
                    </div>
                )}
                {fichaje && fichaje.estado !== 'VERIFICADO' && (
                    <>
                        <div className={classes.buttons}>
                            {!fichaje.operario_verificado && fichaje.admin_verificado && (
                                <Button
                                    color='primaryFilled'
                                    rounded
                                    fullWidth
                                    onClick={() => {
                                        fichajesProvider.verificarFichajes([fichaje.id]).then(onUpdated);
                                    }}
                                >
                                    Verificar fichaje
                                </Button>
                            )}
                            {!fichaje.operario_verificado && !fichaje.admin_verificado ? (
                                <Button color='disabled' rounded fullWidth>
                                    Fichaje pendiente de revisión
                                </Button>
                            ) : (
                                <Button
                                    color={
                                        !fichaje.operario_verificado && fichaje.admin_verificado
                                            ? 'primary'
                                            : 'primaryFilled'
                                    }
                                    outline={!fichaje.operario_verificado && fichaje.admin_verificado}
                                    rounded
                                    fullWidth
                                    onClick={() => {
                                        fichajesProvider.solicitarRevisionFichajes([fichaje.id]).then(onUpdated);
                                    }}
                                >
                                    Solicitar revisión de fichajes
                                </Button>
                            )}
                        </div>
                    </>
                )}
            </SlidingDialog>
        </>
    );
}

FichajeDialog.propTypes = {
    open: PropTypes.any,
    onClose: PropTypes.any,
    fichaje: PropTypes.any,
    onUpdated: PropTypes.any,
};
