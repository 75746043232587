import DateFnsUtils from '@date-io/date-fns';
import { Snackbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import esLocale from 'date-fns/locale/es';
import { SnackbarProvider } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { operariosProvider, tareasProvider } from './api';
import { IS_LOCALHOST } from './api/api-config';
import AlbaranesList from './components/albaranes/AlbaranesList';
import CalendarioPlanificacion from './components/calendario_planificacion/CalendarioPlanificacion';
import ServiceWorkerUpdateChecker from './components/common/ServiceWorkerUpdateChecker';
import ControlJornadaDay from './components/control_jornada/ControlJornadaDay';
import ControlJornadaMonth from './components/control_jornada/ControlJornadaMonth';
import ControlJornadaWeek from './components/control_jornada/ControlJornadaWeek';
import DocumentosPage from './components/documentos/DocumentosPage';
import { FicharView } from './components/fichajes/FicharView/FicharView';
import { HistorialFichajes } from './components/fichajes/HistorialFichajes/HistorialFichajes';
import { Calendario } from './components/jornada/Calendario';
import { JornadaList } from './components/jornada/JornadaList';
import ProyectoFicha from './components/proyectos/ProyectoFicha';
import { ProyectosList } from './components/proyectos/ProyectosList';
import { CalendarioView } from './components/solicitudes/CalendarioView';
import { NuevaSolicitudView } from './components/solicitudes/NuevaSolicitudView';
import { ResumenView } from './components/solicitudes/ResumenView';
import SolicitudListView from './components/solicitudes/SolicitudListView';
import EditarTarea from './components/tareas/editar/EditarTarea';
import EditTareaList from './components/tareas/editar/EditTareaList';
import EditTime from './components/tareas/EditTime';
import { TareaListNew } from './components/tareas/TareaItemList/TareaListNew';
import { TareaList } from './components/tareas/TareaList';
import TareaView from './components/tareas/TareaView';
import useAuthState, { AuthStateProvider } from './contexts/AuthState';
import { SplashProvider } from './contexts/SplashState';
import { format, formatISODateTime } from './utils';
import { getFirebaseToken } from './utils/firebase';

const theme = createTheme({
    palette: {
        primary: { main: '#668AF6' },
        secondary: { main: '#8D8D8D' },
        default: { main: '#999999' },
        neutral: {
            primary: '#A3ACBA',
            dark: '#0B234D',
            dark2: '#091B3C',
            grey1: '#E3E6ED',
            grey4: '#6B6C7E',
        },
        text: {
            primary: '#0B234D',
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    backgroundColor: '#F3F5FA',
                },
            },
        },
        MuiDialogTitle: {
            root: {
                padding: '16px 16px 0 16px',
            },
        },
        MuiDialogContent: {
            root: {
                padding: 16,
            },
        },
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
        body1: {
            // color: '#0B234D',
        },
        h1: {
            fontSize: 24,
            color: 'white',
            fontWeight: 'normal',
            textAlign: 'center',
            padding: '10px 0',
        },
        h2: {
            fontSize: 20,
            color: 'white',
            fontWeight: 600,
            marginTop: 12,
            marginBottom: 8,
        },
        h3: {
            fontSize: 17,
            color: 'white',
            fontWeight: 'normal',
        },
        h4: {
            fontSize: 18,
            color: '#45486E',
            marginTop: 8,
            marginBottom: 16,
        },
        body2: {
            fontFamily: 'inherit',
            fontWeight: 'bold',
        },
        subtitle2: {
            fontSize: 12,
            fontFamily: 'inherit',
        },
        caption: {
            fontSize: 12,
            color: '#6B6C7E',
        },
    },
});

function DefaultSnackbar({ message, action, ButtonProps, SnackbarProps, customParameters }) {
    return (
        <Snackbar {...SnackbarProps}>
            <Alert
                elevation={6}
                variant='filled'
                severity={customParameters?.severity ?? 'info'}
                action={
                    action && (
                        <Button color='inherit' size='small' {...ButtonProps}>
                            {action}
                        </Button>
                    )
                }
            >
                {message || ''}
            </Alert>
        </Snackbar>
    );
}

function MainApp() {
    const {
        userInfo: {
            preferencias: {
                usar_nueva_ui_operarios: usarNuevaUI,
                usar_notificaciones_fichajes: usarNotificacionesFichajes,
            },
        },
    } = useAuthState();

    const history = useHistory();

    useEffect(() => {
        if (navigator.geolocation) {
            const watchId = navigator.geolocation.watchPosition(
                function ({ coords: { latitude, longitude, accuracy } }) {
                    const geolocationHeader = `${latitude},${longitude},${accuracy},${formatISODateTime(new Date())}`;
                    tareasProvider.extraHeaders = {
                        'x-geolocation': geolocationHeader,
                    };
                },
                function () {
                    tareasProvider.extraHeaders = null;
                },
                {
                    enableHighAccuracy: true,
                },
            );

            return () => {
                navigator.geolocation.clearWatch(watchId);
            };
        }
    }, []);

    // const [firebaseToken, setFirebaseToken] = useState(null);

    const handleGetFirebaseToken = () => {
        getFirebaseToken().then((firebaseToken) => {
            if (firebaseToken) {
                console.log('firebase token', firebaseToken);
                operariosProvider.action('token-firebase', {
                    method: 'post',
                    body: JSON.stringify({ token: firebaseToken }),
                });
                // setFirebaseToken(firebaseToken);
            }
        });
    };
    // Need this handle FCM token generation when a user manually blocks or allows notification
    useEffect(() => {
        if (usarNotificacionesFichajes && window.Notification?.permission === 'granted') {
            handleGetFirebaseToken();
        }
    }, []);

    return (
        <>
            {/* {firebaseToken && <textarea name='firebase_token' value={firebaseToken} />} */}
            {usarNotificacionesFichajes && window.Notification && window.Notification.permission !== 'granted' && (
                <div style={{ display: 'flex', gap: '4px', padding: 4 }}>
                    <span>Esta aplicación necesita permisos para </span>
                    <a
                        href='#'
                        className='notification-banner-link'
                        onClick={() =>
                            window.Notification.requestPermission().then((permission) => {
                                if (permission === 'granted') {
                                    window.location.reload();
                                }
                            })
                        }
                    >
                        activar notificaciones push
                    </a>
                </div>
            )}
            <Switch>
                <Route path='/tarea/nueva'>
                    {/* <NuevaTarea /> */}
                    <EditarTarea
                        tareaId={null}
                        onClose={() => history.goBack()}
                        onSave={(tarea) => {
                            history.replace(`/tarea/${tarea.id}`);
                        }}
                    />
                </Route>
                <Route
                    path={['/tarea/:id/tiempo/:fecha', '/tarea/:id/tiempo-jornada/:jornadaId']}
                    render={({ match: { params } }) => (
                        <EditTime id={params.id} fecha={params.fecha} jornadaId={params.jornadaId} />
                    )}
                />
                <Route
                    path={['/tarea/:id/jornadas/:selectedJornadaDate', '/tarea/:id']}
                    render={({ match: { params } }) => (
                        <TareaView id={params.id} selectedJornadaDate={params.selectedJornadaDate} />
                    )}
                />
                <Route
                    path='/control-jornada-day/:fecha?'
                    render={({ match: { params } }) => (
                        <ControlJornadaDay fecha={params.fecha || format(new Date(), 'yyyy-MM-dd')} />
                    )}
                />
                <Route
                    path='/control-jornada-week/:fecha?'
                    render={({ match: { params } }) => (
                        <ControlJornadaWeek fecha={params.fecha || format(new Date(), 'yyyy-MM-dd')} />
                    )}
                />
                <Route
                    path='/control-jornada-month/:fecha?'
                    render={({ match: { params } }) => (
                        <ControlJornadaMonth fecha={params.fecha || format(new Date(), 'yyyy-MM-dd')} />
                    )}
                />
                <Route
                    path='/jornada/:fecha?'
                    render={({ match: { params } }) => <JornadaList fecha={params.fecha} />}
                />
                <Route path='/calendario'>
                    <Calendario />
                </Route>

                <Route path='/albaranes'>
                    <AlbaranesList />
                </Route>

                <Route
                    path='/calendario-planificacion/:fecha?'
                    render={({ match: { params } }) => (
                        <CalendarioPlanificacion fecha={params.fecha || format(new Date(), 'yyyy-MM-dd')} />
                    )}
                />
                <Route path='/fichajes/historico'>
                    <HistorialFichajes />
                    {/* <HistorialFichajesView /> */}
                </Route>
                <Route path='/fichajes'>
                    <FicharView />
                </Route>
                <Route path='/documentos'>
                    <DocumentosPage />
                </Route>
                <Route
                    path='/solicitudes/nueva'
                    render={({ location }) => <NuevaSolicitudView solicitud={location.state?.solicitud} />}
                />
                <Route
                    path='/solicitudes/calendario/:currentYear?/:currentMonth?/:selectedDate?'
                    render={({ match: { params } }) => {
                        const currentMonth = new Date();
                        if (params.currentYear) currentMonth.setFullYear(params.currentYear);
                        if (params.currentMonth) currentMonth.setMonth(params.currentMonth - 1);
                        currentMonth.setDate(1);
                        return <CalendarioView currentMonth={currentMonth} selectedDate={params.selectedDate} />;
                    }}
                />
                <Route
                    path='/solicitudes/lista/:tipo/:estado?'
                    render={({ match: { params } }) => <SolicitudListView tipo={params.tipo} estado={params.estado} />}
                />
                <Route path='/solicitudes'>
                    <ResumenView />
                </Route>
                <Route path='/tareas-admin'>
                    <EditTareaList />
                </Route>
                <Route path='/lista'>
                    <TareaListNew />
                </Route>
                <Route path='/proyectos' exact>
                    <ProyectosList />
                </Route>
                <Route
                    path={[
                        '/proyectos/:id/presupuestos',
                        '/proyectos/:id/observaciones',
                        '/proyectos/:id/solicitudes-material',
                        '/proyectos/:id',
                    ]}
                >
                    <ProyectoFicha />
                </Route>
                <Route path='/'>{usarNuevaUI ? <NewHome /> : <TareaList />}</Route>
            </Switch>
        </>
    );
}

function NewHome() {
    const history = useHistory();
    const { isExact: isRouteExact } = useRouteMatch();

    useEffect(() => {
        if (!isRouteExact) return;

        history.replace('/control-jornada-day');
    }, []);

    return null;
}

function App() {
    const hotjarSettings = { hjid: 2002703, hjsv: 6 };
    if (!IS_LOCALHOST) hotjar.initialize(hotjarSettings.hjid, hotjarSettings.hjsv);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider SnackbarProps={{ autoHideDuration: null }} SnackbarComponent={DefaultSnackbar}>
                    <ServiceWorkerUpdateChecker />
                    <AuthStateProvider>
                        <SplashProvider>
                            <MainApp />
                        </SplashProvider>
                    </AuthStateProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
}

export default App;

DefaultSnackbar.propTypes = {
    ButtonProps: PropTypes.any,
    SnackbarProps: PropTypes.any,
    action: PropTypes.any,
    customParameters: PropTypes.any,
    message: PropTypes.any,
};
